<template>
  <v-container class="container-max-width">
    <v-row class="page-wrapper">
      <v-col offset="3" cols="6">
        <v-row>
          <v-col cols="12" class="align-center">
            <div class="text-center">
              <svg width="130px" height="30px" viewBox="0 0 130 30">
                <circle cx="10" cy="10" r="9" class="filled"/>
                <line x1="19" y1="10" x2="31" y2="10" class="line"/>
                <circle cx="40" cy="10" r="9" class="not-filled"/>
                <line x1="49" y1="10" x2="61" y2="10" class="line"/>
                <circle cx="70" cy="10" r="9" class="not-filled"/>
                <line x1="79" y1="10" x2="91" y2="10" class="line"/>
                <circle cx="100" cy="10" r="9" class="not-filled"/>
              </svg>
            </div>
          </v-col>
        </v-row>
        <v-row class="primary--text heading-text">
          <v-col cols="12" class="text-no-wrap">
            <p class="text-h1 ml-n16">Let's create your account.</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="mt-4">
            <div v-show="errors">
              <v-alert v-for="(value, key) in errors" v-bind:key="key" class="error rounded" transition="fade-transition" dismissible>
                <span class="white--text">{{ value }}</span>
              </v-alert>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-2">
          <v-col>
            <v-card class="pl-9 pr-9 rounded-lg" :loading="this.isLoading">
              <v-col cols="12" class="pt-10">
                <v-text-field
                    clearable
                    outlined
                    label="Name"
                    type="text"
                    class="rounded-0"
                    v-model="name"
                    @change="$v.name.$touch()"
                    :error-messages="checkNameErrors"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                    clearable
                    outlined
                    label="E-mail Address"
                    type="email"
                    class="rounded-0 mt-n4"
                    v-model="email"
                    @change="$v.email.$touch()"
                    :error-messages="checkEmailErrors"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    clearable
                    outlined
                    label="Password"
                    :type="showPassword ? 'text' : 'password'"
                    class="rounded-0 mt-n4"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    hint="Passwords must contain at least 8 letters and/or numbers"
                    v-model="password"
                    @change="$v.password.$touch()"
                    :error-messages="checkPasswordErrors"
                    persistent-hint
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    clearable
                    outlined
                    label="Confirm Password"
                    :type="showConfirmPassword ? 'text' : 'password'"
                    class="rounded-0 mt-n4"
                    :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showConfirmPassword = !showConfirmPassword"
                    hint="Confirm Password must match the password"
                    v-model="confirmPassword"
                    @change="$v.confirmPassword.$touch()"
                    :error-messages="checkConfirmPasswordErrors"
                    persistent-hint
                >
                </v-text-field>
              </v-col>
              <v-row class="mt-n6">
                <v-col class="ml-5">
                  <v-checkbox
                      class="rounded"
                      v-model="agreeTermsAndConditions"
                      @change="$v.agreeTermsAndConditions.$touch()"
                      :error-messages="checkTermsErrors"
                  >
                    <template v-slot:label>
                      <p class="text-caption mb-n1">I agree to Syncio’s <router-link to="/privacy-policy">Privacy Policy</router-link> and <router-link to="terms-and-condition">Terms & Conditions</router-link>.</p>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mt-10">
          <v-col>
            <v-btn
                block
                class="primary create-account-button"
                height="48px"
                @click="registerSubmit(name,email,password,confirmPassword)"
            >Create Account      <v-icon class="right-arrow">
              mdi-arrow-right
            </v-icon></v-btn
            ></v-col
          >
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { email, required, minLength, sameAs } from "vuelidate/lib/validators";
import {REGISTER, REMOVE_ERRORS} from "@/store/actions.type"
export default {
  name: "CreateAccount",
  data() {
    return {
      showPassword: false,
      showConfirmPassword: false,
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      agreeTermsAndConditions: false
    };
  },
  validations: {
    name: { required },
    email: { required, email },
    password: { required, minLength: minLength(8) },
    confirmPassword: { required, sameAsPassword: sameAs("password") },
    agreeTermsAndConditions: { sameAs: sameAs( () => true )}
  },
  created() {
    this.$store.dispatch(`auth/${REMOVE_ERRORS}`);
  },
  computed: {
    ...mapGetters("auth", ['isLoading', 'errors']),
    checkNameErrors() {
      const nameErrors = [];
      if (!this.$v.name.$dirty) return nameErrors;
      !this.$v.name && nameErrors.push("Must be valid name");
      !this.$v.name.required && nameErrors.push("Name is required");
      return nameErrors;
    },
    checkEmailErrors () {
      const emailErrors = [];
      if (!this.$v.email.$dirty) return emailErrors
      !this.$v.email.email && emailErrors.push('Must be valid e-mail')
      !this.$v.email.required && emailErrors.push('E-mail is required')
      return emailErrors
    },
    checkPasswordErrors () {
      const passwordErrors = []
      if (!this.$v.password.$dirty) return passwordErrors
      !this.$v.password.required && passwordErrors.push('Password is required')
      !this.$v.password.minLength && passwordErrors.push('Password lenght should be greater than 8')
      return passwordErrors
    },
    checkConfirmPasswordErrors() {
      const confirmPasswordErrors = [];
      if (!this.$v.confirmPassword.$dirty) return confirmPasswordErrors;
      !this.$v.confirmPassword.required &&
      confirmPasswordErrors.push("confirmPassword is required");
      !this.$v.confirmPassword.sameAsPassword &&
      confirmPasswordErrors.push("Must be same as password");
      return confirmPasswordErrors;
    },
    checkTermsErrors () {
      const termsErrors = []
      if (!this.$v.agreeTermsAndConditions.$dirty) return termsErrors
      !this.$v.agreeTermsAndConditions.sameAs && termsErrors.push('Please agree to terms and conditions')
      return termsErrors
    },
  },
  methods: {
    registerSubmit(name, email, password, confirmPassword){
      this.$v.$touch();
      if (!this.$v.$invalid){
        this.$store.dispatch(`auth/${REGISTER}`, {
          name: name,
          email: email,
          password: password,
          password_confirmation: confirmPassword
        }).then(() => {
          this.$router.push({name: 'registration.woocommerce.select-store-type'})
        }).catch((error) => {
          console.log(error.data)
        });
      }
    }
  },
}
</script>

<style scoped>
.page-wrapper{
  margin-top: 3%;
}
.heading-text{
  margin-top: 7%;
}
.filled{
  fill: #fcb058;
  stroke-width: 1;
  stroke: #f57c00;
}
.not-filled{
  fill: none;
  stroke-width: 1;
  stroke: #f57c00;
}
.line{
  stroke-width: 2;
  stroke:#f57c00
}
.create-account-button{
  text-transform: capitalize;
}
.right-arrow{
  margin-left: 10px;
  margin-top: 4px;
  width: 24px;
  height: 24px;
}
.container-max-width{
  max-width: 1200px;
  min-width: 768px !important;
}
</style>